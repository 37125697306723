import React, { CSSProperties, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import bridge from "~src/backend/bridge";
import Plan from "~src/backend/Plan";
import T from "~src/components";
import R from "~src/res";
import theme from "~src/theme";
import toolbox from "~src/toolbox";
import type * as types from "~src/types";

const IncrementalItem: React.FC<{
  activeLicense: types.License;
  upgradeModalRef: React.RefObject<UpgradeModalInstance>;
  renewalModalRef: React.RefObject<RenewalModalInstance>;
}> = (props) => {

  const {
    activeLicense,
    upgradeModalRef,
    renewalModalRef,
  } = props;

  const [ upgradeOptions, setUpgradeOptions ] = useState<types.IncrementalOption["upgrade"]>();
  const [ renewalOptions, setRenewalOptions ] = useState<types.IncrementalOption["renewal"]>();

  const permanent = activeLicense.expiry.startsWith("9999");
  const unlimited = activeLicense.max_users === Plan.UNLIMITED_USER;

  useEffect(() => {
    (async () => {
      if (permanent && unlimited) {
        return;
      }
      const r = await bridge.getIncrementalOptions({
        licenseId: activeLicense.id,
      });
      if (r?.status !== "OK") {
        return;
      }
      const response = r.data as types.IncrementalOption;
      setUpgradeOptions(response.upgrade);
      setRenewalOptions(response.renewal);
    })();
  }, [
  ]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      {upgradeOptions && Object.keys(upgradeOptions).length > 0 &&
        <UpgradeItem
          activeLicense={activeLicense}
          upgradeModalRef={upgradeModalRef}
          options={upgradeOptions}
        />
      }
      {renewalOptions && Object.keys(renewalOptions).length > 0 &&
        <RenewalItem
          activeLicense={activeLicense}
          renewalModalRef={renewalModalRef}
          options={renewalOptions}
        />
      }
    </div>
  );
};

const UpgradeItem: React.FC<{
  activeLicense: types.License;
  upgradeModalRef: React.RefObject<UpgradeModalInstance>;
  options: types.IncrementalOption["upgrade"];
}> = (props) => {

  const {
    activeLicense,
    upgradeModalRef,
    options,
  } = props;

  const { t } = useTranslation();

  const unlimited = activeLicense.max_users === Plan.UNLIMITED_USER;

  const onClick = () => {
    upgradeModalRef.current?.open({
      license: activeLicense,
      options: options,
    });
  };

  if (unlimited) {
    return null;
  }

  return (
    <T.Button
      style={commonIncrementalButtonStyle}
      type={"link"}
      onClick={onClick}
    >
      {t("upgrade")}
    </T.Button>
  );
};


const UpgradeModalItem = React.forwardRef<UpgradeModalInstance>((props, ref) => {

  const { t } = useTranslation();
  const navigate = toolbox.useNavigate();

  const [ open, setOpen ] = useState(false);
  const [ license, setLicense ] = useState<types.License>();
  const [ options, setOptions ] = useState<types.IncrementalOption["upgrade"]>({});
  const [ userCap, setUserCap ] = useState<number>();

  const optionEntries = useMemo(() => (
    Object.entries(options).map(([ idStr, price]) => [ Number(idStr), price ])
  ), [
    options,
  ]);

  const onConfirm = async () => {
    if (!license) {
      console.error("license is undefined");
      return;
    }
    if (userCap === undefined) {
      console.error("user cap is undefined");
      return;
    }
    const r = await bridge.createUpgradeOrder({
      licenseId: license.id,
      user: userCap,
    });
    if (r?.status !== "OK") {
      console.error("cannot create order");
      return;
    }
    navigate("/user/order");
  };

  const onRequestClose = () => {
    setOpen(false);
  };

  const onRenewalSelectChange = (value: number) => {
    setUserCap(value);
  };

  useImperativeHandle(ref, () => ({
    open: ({ license, options}) => {
      setLicense(license);
      setOptions(options);
      setOpen(true);
      return open;
    },
  }));

  useEffect(() => {
    if (open) {
      onRenewalSelectChange(optionEntries[0]?.[0]);
    }
  }, [
    open,
  ]);

  return (
    <T.Modal
      isOpen={open}
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <T.Typography
            style={{
              alignSelf: "center",
              fontWeight: 600,
              fontSize: "16px",
              color: theme.palette.dark,
            }}
          >
            {t("upgrade")}
          </T.Typography>
          <T.Icon
            icon={R.CloseIcon}
            style={{
              padding: "6px",
            }}
            onClick={onRequestClose}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gap: "12px 16px",
          }}
        >
          <RowItem
            title={t("options")}
          >
            <T.Select
              style={{
                minWidth: "180px",
              }}
              defaultValue={Number(optionEntries[0]?.[0])}
              onChange={onRenewalSelectChange}
            >
              {optionEntries.map(([pid]) => (
                <T.Select.Option
                  key={pid}
                  value={pid}
                >
                  {Plan.getTitle(Plan.Users, pid) ?? pid}
                </T.Select.Option>
              ))}
            </T.Select>
          </RowItem>
          <RowItem
            title={t("price")}
          >
            <T.Typography
              style={{
                color: theme.palette.dark,
                textAlign: "end",
              }}
            >
              {userCap !== undefined ? `$ ${options[userCap]}` : "-"}
            </T.Typography>
          </RowItem>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: "16px",
          }}
        >
          <T.Button
            onClick={onConfirm}
          >
            {t("confirm")}
          </T.Button>
          <T.Button
            onClick={onRequestClose}
          >
            {t("cancel")}
          </T.Button>
        </div>
      </div>
    </T.Modal>
  );
});

const RenewalItem: React.FC<{
  activeLicense: types.License;
  renewalModalRef: React.RefObject<RenewalModalInstance>;
  options: types.IncrementalOption["renewal"];
}> = (props) => {

  const {
    activeLicense,
    renewalModalRef,
    options,
  } = props;

  const { t } = useTranslation();

  const permanent = activeLicense.expiry.startsWith("9999");

  const onClick = () => {
    renewalModalRef.current?.open({
      license: activeLicense,
      options: options,
    });
  };

  if (permanent) {
    return null;
  }

  return (
    <T.Button
      style={commonIncrementalButtonStyle}
      type={"link"}
      onClick={onClick}
    >
      {t("renew")}
    </T.Button>
  );
};

const RenewalModalItem = React.forwardRef<RenewalModalInstance>((props, ref) => {

  const { t } = useTranslation();
  const navigate = toolbox.useNavigate();

  const [ open, setOpen ] = useState(false);
  const [ license, setLicense ] = useState<types.License>();
  const [ options, setOptions ] = useState<types.IncrementalOption["renewal"]>({});
  const [ period, setPeriod ] = useState<number>();

  const optionEntries = useMemo(() => (
    Object.entries(options).map(([ idStr, price]) => [ Number(idStr), price ])
  ), [
    options,
  ]);

  const onConfirm = async () => {
    if (!license) {
      console.error("license is undefined");
      return;
    }
    if (period === undefined) {
      console.error("period is undefined");
      return;
    }
    const r = await bridge.createRenewalOrder({
      licenseId: license.id,
      period: period,
    });
    if (r?.status !== "OK") {
      console.error("cannot create order");
      return;
    }
    navigate("/user/order");
  };

  const onRequestClose = () => {
    setOpen(false);
  };

  const onRenewalSelectChange = (value: number) => {
    setPeriod(value);
  };

  useImperativeHandle(ref, () => ({
    open: ({ license, options}) => {
      setLicense(license);
      setOptions(options);
      setOpen(true);
      return open;
    },
  }));

  useEffect(() => {
    if (open) {
      onRenewalSelectChange(optionEntries[0]?.[0]);
    }
  }, [
    open,
  ]);

  return (
    <T.Modal
      isOpen={open}
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <T.Typography
            style={{
              alignSelf: "center",
              fontWeight: 600,
              fontSize: "16px",
              color: theme.palette.dark,
            }}
          >
            {t("renew")}
          </T.Typography>
          <T.Icon
            icon={R.CloseIcon}
            style={{
              padding: "6px",
            }}
            onClick={onRequestClose}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gap: "12px 16px",
          }}
        >
          <RowItem
            title={t("options")}
          >
            <T.Select
              style={{
                minWidth: "180px",
              }}
              defaultValue={Number(optionEntries[0]?.[0])}
              onChange={onRenewalSelectChange}
            >
              {optionEntries.map(([pid]) => (
                <T.Select.Option
                  key={pid}
                  value={pid}
                >
                  {Plan.getTitle(Plan.Periods, pid) ?? pid}
                </T.Select.Option>
              ))}
            </T.Select>
          </RowItem>
          <RowItem
            title={t("price")}
          >
            <T.Typography
              style={{
                color: theme.palette.dark,
                textAlign: "end",
              }}
            >
              {period !== undefined ? `$ ${options[period]}` : "-"}
            </T.Typography>
          </RowItem>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: "16px",
          }}
        >
          <T.Button
            onClick={onConfirm}
          >
            {t("confirm")}
          </T.Button>
          <T.Button
            onClick={onRequestClose}
          >
            {t("cancel")}
          </T.Button>
        </div>
      </div>
    </T.Modal>
  );
});

const ExportModalItem = React.forwardRef<ExportModalInstance, {
  license?: types.License;
  cloudJsonRef: React.RefObject<HTMLTextAreaElement>;
  cloudProxyRef: React.RefObject<HTMLTextAreaElement>;
  onExport: () => void;
  onExportEncrypted: () => void;
}>((props, ref) => {

  const {
    license,
    cloudJsonRef,
    cloudProxyRef,
    onExport,
    onExportEncrypted,
  } = props;

  const { t } = useTranslation();

  const [ open, setOpen ] = useState(false);
  const [ defaultTexts, setDefaultTexts ] = useState<CloudConfigBrowserStorage>({
    cloudJson: "",
    cloudProxy: "",
  });

  const storeCloudConfig = () => {
    if (!license) {
      return;
    }
    window.sessionStorage.setItem(getStorageKey(license.name), JSON.stringify({
      cloudJson: cloudJsonRef.current!.value ?? "",
      cloudProxy: cloudProxyRef.current!.value ?? "",
    } satisfies CloudConfigBrowserStorage));
  };

  const onRequestClose = () => {
    setOpen(false);
  };

  const onExportClick = () => {
    storeCloudConfig();
    onExport();
  };

  const onExportEncryptedClick = () => {
    storeCloudConfig();
    onExportEncrypted();
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      const _ = open;
      setOpen(true);
      return _;
    },
  }));

  useEffect(() => {
    if (!open) {
      return;
    }
    if (!license) {
      return;
    }
    let config: CloudConfigBrowserStorage = Object.create(null);
    try{
      config = JSON.parse(window.sessionStorage.getItem(getStorageKey(license.name)) ?? "");
    } catch(e) {
    }
    const _: CloudConfigBrowserStorage = Object.create(null);
    if (config.cloudJson) {
      _.cloudJson = config.cloudJson;
    }
    if (config.cloudProxy) {
      _.cloudProxy = config.cloudProxy;
    }
    setDefaultTexts(_);
  }, [
    open,
  ]);

  return (
    <T.Modal
      isOpen={open}
    >
      <div
        style={{
          minWidth: "500px",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <T.Typography
            style={{
              alignSelf: "center",
              fontWeight: 600,
              fontSize: "16px",
              color: theme.palette.dark,
            }}
          >
            {t("export_oss")}
          </T.Typography>
          <T.Icon
            icon={R.CloseIcon}
            style={{
              padding: "6px",
            }}
            onClick={onRequestClose}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            gap: "12px 16px",
          }}
        >
          <RowItem
            title={`${t("oss_exclusive")}\n${t("parentheses", { content: t("in_json")})}\n${t("optional")}`}
            textStyle={{
              alignSelf: "flex-start",
            }}
          >
            <T.TextArea
              ref={cloudJsonRef}
              key={defaultTexts.cloudJson}
              placeholder={jsonConfigPlaceholder}
              defaultValue={defaultTexts.cloudJson}
              autoSize={{
                minRows: 8,
                maxRows: 8,
              }}
              spellCheck={false}
            />
          </RowItem>
          <RowItem
            title={`${t("oss_proxy")}\n${t("optional")}`}
            textStyle={{
              alignSelf: "flex-start",
            }}
          >
            <T.TextArea
              ref={cloudProxyRef}
              key={defaultTexts.cloudProxy}
              defaultValue={defaultTexts.cloudProxy}
              autoSize={{
                minRows: 4,
                maxRows: 4,
              }}
              spellCheck={false}
            />
          </RowItem>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            gap: "16px",
          }}
        >
          <T.Button
            onClick={onExportClick}
          >
            {t("export_oss_json")}
          </T.Button>
          <T.Button
            onClick={onExportEncryptedClick}
          >
            {t("export_oss_encrypted")}
          </T.Button>
        </div>
      </div>
    </T.Modal>
  );
});

const RowItem: React.FC<React.PropsWithChildren<{
  title: string;
  textStyle?: React.CSSProperties;
}>> = (props) => {

  const {
    title,
    textStyle,
    children,
  } = props;

  return (<>
    <T.Typography
      style={{
        maxWidth: "120px",
        fontSize: "14px",
        wordBreak: "keep-all",
        color: theme.palette.neutral[7],
        ...textStyle,
      }}
    >
      {title}
    </T.Typography>
    {children}
  </>);
};

const getStorageKey = (name: string) => `cloud-config-${name}`;

const commonIncrementalButtonStyle: CSSProperties = {
  padding: "4px",
};

const jsonConfigPlaceholder = `{
  "CurrencySymbol": "$",
  "DisableInAppPurchase": false,
  "QuickLoginSsp": true,
  "ApiDoh": "208.67.222.222",
  "CustomSubscriptionUrl": "https://a.b.c"
}`;

interface UpgradeModalInstance {
  open: (_: {
    license: types.License;
    options: types.IncrementalOption["upgrade"];
  }) => boolean;
}

interface RenewalModalInstance {
  open: (_: {
    license: types.License;
    options: types.IncrementalOption["renewal"];
  }) => boolean;
}

interface ExportModalInstance {
  open: () => boolean;
}

interface CloudConfigBrowserStorage {
  cloudJson: string;
  cloudProxy: string;
}

const UserApp2 = {
  ExportModalItem,
  IncrementalItem,
  RenewalModalItem,
  UpgradeModalItem,
};

export default UserApp2;
export type {
  ExportModalInstance,
  RenewalModalInstance,
  UpgradeModalInstance,
};
