import JSON5 from "json5";
import yaml from "yaml";
import { DateTime, Duration } from "luxon";
import { ForwardedRef, MutableRefObject, RefCallback, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { Md5 } from "ts-md5";
import bridge from "~src/backend/bridge";

const colourHexAlpha = (hex: string, alpha: number): string => {
  return `${hex}${(255 * alpha >>> 0).toString(16).padStart(2, '0')}`;
};

const copyPropertiesIfPresent = (names: string[], src: any, dst: any): any => {
  for (const name of names) {
    const _ = src[name];
    if (_ !== undefined) {
      dst[name] = _;
    }
  }
};

function copyNonEmptyProperties(source: any): any {
  const result = Object.create(null);

  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      const value = source[key];
      if (value) {
        if (Array.isArray(value) && value.length === 0) {
          continue;
        }
        result[key] = value;
      }
    }
  }

  return result;
}

const formatDate = (date: string) => {
  return DateTime.fromISO(date).toFormat("MM-dd T");
};

const reactPropagateRef = <T>(ref: ForwardedRef<T>, obj: T) => {
  if (ref == null) {
  } else if (typeof ref == "object") {
    ref.current = obj;
  } else if (typeof ref == "function") {
    ref(obj);
  }
};

const reactDuplicateRef = <T>(ref: MutableRefObject<T | null>, parentRef: ForwardedRef<T>) => {
  const refCallback: RefCallback<T> = (_) => {
    ref.current = _;
    reactPropagateRef(parentRef, _);
  };
  return refCallback;
};


const useEffectOnce = (f: () => void): () => void => {
  const _ = window.setTimeout(() => {
    f();
  }, 0);
  return () => window.clearTimeout(_);
};

const useAsyncEffectOnce = (f: () => Promise<void>): () => void => {
  return useEffectOnce(() => {
    f().catch((reason) => {
      if (reason instanceof Error) {
        throw reason;
      }
    });
  });
};

const _useNavigate: typeof useNavigate = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return ((...args: [any]) => {
    navigate(...args);
    window.scrollTo(0, 0);
  });
};

const getMd5 = async (file: File): Promise<string> => {
  const md5 = new Md5();
  const ab = await file.arrayBuffer();
  md5.appendByteArray(new Uint8Array(ab));
  return md5.end() as string;
};

const isValidJson5 = (s: string) => {
  try {
    JSON5.parse(s);
    return true;
  } catch (e) {
    return false;
  }
};

const isValidYaml = (s: string) => {
  try {
    const fork = yaml.parse(s);
    return true;
  } catch (e) {
    return false;
  }
};

const mangleClashProxy = (s: string): string => {
  let mangled = s.replace(/(?<=:\s*)\S.*?(?=\s*[,}])/g, (_) => {
    if (_.startsWith('"') && _.endsWith('"')) {
      return _;
    }
    const regExps = [
      /^[0-9]+$/,
      /^(?:true|false)$/,
    ];
    for (const re of regExps) {
      if (re.test(_)) {
        return _;
      }
    }
    return `"${_}"`;
  });
  return mangled;
};

const bracket = (content: string | null | undefined): string => {
  return content ? `(${content})` : "";
};

const commaPrefix = (content: string | null | undefined): string => {
  return content ? `, ${content}` : "";
};

// ****************  project specific  ****************

const formatExpiryDate = (date: DateTime, upper: Duration = defaultExpiryDurationThreshold) => {
  if (upper) {
    if (date.diffNow().minus(upper).shiftTo("seconds").seconds > 0) {
      return "";
    }
  }
  return `expires on ${date.toLocaleString(undefined, {locale: navigator.language})}`;
};

const formatExpiryDuration = (duration: Duration, upper: Duration = defaultExpiryDurationThreshold): string => {
  if (upper) {
    if (duration.minus(upper).shiftTo("seconds").seconds > 0) {
      return "";
    }
  }
  return duration.shiftTo("months", "days").set({hours: undefined}).toHuman({maximumFractionDigits: 0});
};

const defaultExpiryDurationThreshold = Duration.fromObject({
  years: 20,
});

// ********************************

const toolbox = {
  bracket,
  colourHexAlpha,
  copyNonEmptyProperties,
  copyPropertiesIfPresent,
  commaPrefix,
  formatDate,
  formatExpiryDate,
  formatExpiryDuration,
  getMd5,
  isValidJson5,
  isValidYaml,
  mangleClashProxy,
  reactPropagateRef,
  reactDuplicateRef,
  useAsyncEffectOnce,
  useEffectOnce,
  useNavigate: _useNavigate,
};

type AsyncReturnType<T extends (...args: any[]) => any> = Awaited<ReturnType<T>>;

export default toolbox;
export type {
  AsyncReturnType,
};
